// Generated by Framer (9045c1a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { useRef } from "react";

const cycleOrder = ["hDDESKXiX", "DPYwPaX_Y"];

const serializationHash = "framer-Ba3eT"

const variantClassNames = {DPYwPaX_Y: "framer-v-1wx847h", hDDESKXiX: "framer-v-1oozvs2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const humanReadableVariantMap = {closed: "hDDESKXiX", open: "DPYwPaX_Y"}

const getProps = ({click, height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "hDDESKXiX", W41POyFF1: click ?? props.W41POyFF1} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;click?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const fallbackRef = useRef<HTMLElement>(null)

const refBinding = ref ?? fallbackRef

const defaultLayoutId = React.useId()

const { activeLocale, setLocale } = useLocaleInfo()

const componentViewport = useComponentViewport()

const {style, className, layoutId, variant, W41POyFF1, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "hDDESKXiX", ref: refBinding, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap2mtbcc = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("DPYwPaX_Y")
})

const onTap1qmyyss = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (W41POyFF1) {const res = await W41POyFF1(...args);
if (res === false) return false;}
setVariant("hDDESKXiX")
})

const sharedStyleClassNames = []

const scopingClassNames = cx(serializationHash, ...sharedStyleClassNames)

const isDisplayed = () => {
if (baseVariant === "DPYwPaX_Y") return false
return true
}

return (<LayoutGroup id={layoutId ?? defaultLayoutId}><Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(scopingClassNames, "framer-1oozvs2", className, classNames)} data-framer-name={"closed"} data-highlight layoutDependency={layoutDependency} layoutId={"hDDESKXiX"} onTap={onTap2mtbcc} ref={refBinding} style={{...style}} {...addPropertyOverrides({DPYwPaX_Y: {"data-framer-name": "open", onTap: onTap1qmyyss}}, baseVariant, gestureVariant)}><motion.div className={"framer-al63xs"} data-framer-name={"line3"} layoutDependency={layoutDependency} layoutId={"y9JbQ9VeJ"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{DPYwPaX_Y: {rotate: 45}}}/>{isDisplayed() && (<motion.div className={"framer-1fb3hfe"} data-framer-name={"line2"} layoutDependency={layoutDependency} layoutId={"PnL4HASpY"} style={{backgroundColor: "rgb(255, 255, 255)"}}/>)}<motion.div className={"framer-aysi17"} data-framer-name={"line 1"} layoutDependency={layoutDependency} layoutId={"oVk53X9HK"} style={{backgroundColor: "rgb(255, 255, 255)", rotate: 0}} variants={{DPYwPaX_Y: {rotate: -45}}}/></motion.div></Transition></Variants></LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ba3eT.framer-14z0inf, .framer-Ba3eT .framer-14z0inf { display: block; }", ".framer-Ba3eT.framer-1oozvs2 { cursor: pointer; height: 31px; overflow: hidden; position: relative; width: 31px; }", ".framer-Ba3eT .framer-al63xs { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: 6px; width: 27px; }", ".framer-Ba3eT .framer-1fb3hfe { flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; top: calc(51.61290322580647% - 2px / 2); width: 27px; }", ".framer-Ba3eT .framer-aysi17 { bottom: 5px; flex: none; height: 2px; left: calc(51.61290322580647% - 27px / 2); overflow: visible; position: absolute; width: 27px; }", ".framer-Ba3eT.framer-v-1wx847h.framer-1oozvs2 { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 31px); }", ".framer-Ba3eT.framer-v-1wx847h .framer-al63xs { top: calc(51.61290322580647% - 2px / 2); }", ".framer-Ba3eT.framer-v-1wx847h .framer-aysi17 { bottom: unset; top: calc(51.61290322580647% - 2px / 2); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 31
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"DPYwPaX_Y":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"W41POyFF1":"click"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerab4033W_K: React.ComponentType<Props> = withCSS(Component, css, "framer-Ba3eT") as typeof Component;
export default Framerab4033W_K;

Framerab4033W_K.displayName = "Navigation/hamburger";

Framerab4033W_K.defaultProps = {height: 31, width: 31};

addPropertyControls(Framerab4033W_K, {variant: {options: ["hDDESKXiX", "DPYwPaX_Y"], optionTitles: ["closed", "open"], title: "Variant", type: ControlType.Enum}, W41POyFF1: {title: "Click", type: ControlType.EventHandler}} as any)

addFonts(Framerab4033W_K, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})